














































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Goal, AssignedGoal } from "@/core/models";
import { Component, Watch } from "vue-property-decorator";

import Pages from "@/core/utils/Pages";
import PagesRender from "@/components/common/PagesRender.vue";
import { InformationTooltip } from "@/components/common";

import { CreatedGoal, AddGoalButton, AssignedGoalItem } from "./components";
import { createGoalsTour, Tour } from "@/core/utils/siteTour";
@Component({
  components: {
    CreatedGoal,
    PagesRender,
    AddGoalButton,
    AssignedGoalItem,
    InformationTooltip,
  },
})
export default class Goals extends Vue {
  @Getter("goals/loading") loading!: boolean;
  @Getter("goals/retrieved") retrieved!: boolean;
  @Getter("goals/getCreatedGoals") createdGoals!: Goal[];
  @Getter("goals/getAssignedGoals") assignedGoals!: AssignedGoal[];
  @Action("goals/getGoals") getGoals!: () => Promise<void>;
  @Action("goals/removeGoal") removeGoal!: (goalId: number) => Promise<void>;

  @Getter("profile/tutorialStep") tutorialStep!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;

  pagesCreated: Pages<Goal> = Pages.Empty();
  pagesAssigned: Pages<AssignedGoal> = Pages.Empty();
  makePages() {
    this.pagesCreated = new Pages<Goal>(10, this.createdGoals);
    this.pagesAssigned = new Pages<AssignedGoal>(10, this.assignedGoals);
  }

  @Watch("loading", { immediate: true })
  dataLoaded() {
    if (!this.loading) this.makePages();
  }
  @Watch("createdGoals", { immediate: true })
  createdGoalsUpdate() {
    if (!this.loading) this.makePages();
  }
  @Watch("assignedGoals", { immediate: true })
  assignedGoalsUpdate() {
    if (!this.loading) this.makePages();
  }

  tour: Tour | null = null;
  startTour() {
    this.tour = createGoalsTour();
    this.tour.start();
  }
  @Watch("$route", { immediate: true })
  routeChanged() {
    const { tour } = this.$route.query;
    if (tour === "true" && this.showTutorial && this.tutorialStep === 2)
      this.startTour();
  }

  async onDeleteGoal(goalId: number): Promise<void> {
    try {
      await this.removeGoal(goalId);
    } catch (error) {
      console.error("Fehler beim Löschen des Ziels:", error);
    }
  }

  created() {
    if (!this.retrieved) this.getGoals();
  }
}
